<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Users on PND</h2>
      </div>
    </div>
    <div>
      <div class="border border-solid border-blue-200 rounded overflow-hidden">
        <datatable
          :url="`${this.$baseurl}/admin/personal/users/status/post-no-debit`"
          :ajax="true"
          :ajaxPagination="true"
          :exportable="true"
          :index="true"
          :fillable="true"
          :columns="creditAccounts.columns"
          :loading="creditAccounts.loading"
          :pageDetails="true"
          :actions="actions"
          :query="searchQuery"
          :data="creditAccounts.data"
          :onClick="click"
          ref="table"
        >
          <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-2"
              v-model="searchQuery"
              @submit="reloadTable"
            />
            <div class="flex items-start flex-wrap xl:flex-no-wrap">
              <div class="flex flex-wrap items-center xl:ml-auto">
                <span class="processor-filter-title">Exports:</span>
                <button
                  class="btn btn-purple-outline btn-sm mb-2 mr-2"
                  @click.prevent="$refs.table.exportAll()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export All Results</span>
                </button>
                <!-- <button
                  class="btn btn-blue-outline btn-sm mb-2"
                  @click.prevent="$refs.table.exportData()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export Filtered Results</span>
                </button> -->
              </div>
            </div>
          </div>
        </template>
        </datatable>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="deactivatePndModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm PND Deactivation</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeDeactivatePndModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="DeactivatePnd"
        >
          <span v-if="loading">Deactivating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.successModal.close()"
      >
        Okay
      </button>
    </modal>

  </div>
</template>
<script>

export default {
  data() {
    return {
      requestError: null,
      searchQuery: "",
      loading: false,
      actions: [
        {
          text: "Deactivate PND",
          class:
            "border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
          action: this.openDeactivatePndModal,
        },
       
      ],
      selectedUser: null,
      creditAccounts: this.$options.resource([], {
        query: "",
        columns: [
          {
            name: "user",
            th: "User",
            // render: card => card.personal_account?.user?.name
            render: (account) => {
              if (!account) {
                return "N/A";
              }
              return `${account?.name} ${account?.last_name}`;
            },
          },
          {
            name: "email",
            th: "Email Address",
            render: (account) => {
              if (account?.email) {
                return `${account?.email}`;
              }
            },
          },
          {
            name: "credit_card_limit",
            th: "Credit Limit",
            render: (account) => {
              if (account?.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  account?.personal_account?.credit_card_limit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
              
            },
          },

          {
            name: "available_balance",
            th: "Available Balance",
            render: (account) => {
              if (account?.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  account?.personal_account?.available_balance
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
              
            },
          },
        {
            name: "loan_limit",
            th: "Standard Loan Limit",
            render: (account) => {
              if (account?.profile) {
               return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  account?.profile?.standard_loan_limit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
              
            },
          },
          {
            name: 'status',
            th: 'Status',
            render: (user, status) => {
              return this.statusBadge(status, user);
            }
          },
        ],
      }),
      creditCardComment: this.$options.basicForm(["body"]),
      currentcreditCardComments: [],
      actionError: null,
      commentsLoading: false,
      selectedAccount: null,
      successMessage: "",
    };
  },
  computed: {
   
  },
  beforeMount() {
   
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    showActions() {
      if (!this.canChargeUsers) {
        this.actions = [];
        return;
      }
    },
    removeError() {
      this.requestError = "";
    },
    openDeactivatePndModal(user) {
      this.selectedUser = user;
      this.$refs.deactivatePndModal.open();
    },
    closeDeactivatePndModal() {
      this.$refs.deactivatePndModal.close();
    },

    reloadTable() {
      this.$refs.table.loadAjaxData();
    },

    click(user) {
      this.selectedUser = user;
      this.$refs.viewModal.open();
    },

    async DeactivatePnd() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/deactivate-post-no-debit/${this.selectedUser.id}`,
        headers: this.headers,
        success: () => {
          this.reloadTable();
          this.closeDeactivatePndModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="sass">
.processor-select-input
  @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600
  font-size: 13px
  &:hover
    @apply bg-blue-200 border-blue-200 text-blue-900
  &:focus-within
    @apply border-blue-500 text-blue-500
.processor-filter-title
  @apply font-bold mb-2 mr-2 text-sm uppercase w-full
.processor-filter-item
  @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-3 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase
  &:hover
    @apply bg-blue-100 border-blue-500 text-blue-500
  &.active
    @apply bg-blue-500 border-blue-500 text-white
</style>
