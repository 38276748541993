<template>
  <div>
    <h2 class="text-2xl font-bold mr-3 mb-5">Appeal Requests</h2>
    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :url="requests.url"
        :ajax="true"
        :ajax-pagination="true"
        :exportable="true"
        :index="true"
        :fillable="true"
        :columns="requests.columns"
        :loading="requests.loading"
        :page-details="true"
        :actions="requests.actions"
        :query="requests.searchQuery"
        :data="requests.data"
        ref="table"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, email etc."
              class="mb-2"
              v-model="requests.searchQuery"
              @submit="$refs.table.loadAjaxData()"
            />
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      requests: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/appeal-requests`,
        searchQuery: '',
        columns: [
          {
            name: 'name',
            th: 'Name',
            render: ({ user }) => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'comment',
            th: 'Comment'
          },
          {
            name: 'status',
            th: 'Status',
            render: ({ status }) =>
              `<div class="${
                status === 'approved' ? 'text-green-500' : 'text-orange-600'
              } text-sm">${status}</div>`
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (loan, created_at) =>
              this.$options.filters.dateFormat(created_at, 'D, dd M Y')
          }
        ],
        actions: [
          {
            text: 'Decline Request',
            class: 'border-red-500 text-red-500 rounded-sm px-4 py-2',
            action: this.declineRequest
          },
          {
            text: 'Approve Request',
            class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
            action: this.approveRequest
          }
        ]
      })
    };
  },
  methods: {
    async approveRequest({ user_id }) {
      await this.sendRequest('admin.users.appealRequests.approve', user_id, {
        success: () => this.$refs.table.loadAjaxData(),
        error: error => console.log(error)
      });
    },
    async declineRequest({ user_id }) {
      await this.sendRequest('admin.users.appealRequests.decline', user_id, {
        success: () => this.$refs.table.loadAjaxData(),
        error: error => console.log(error)
      });
    }
  }
};
</script>
